import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/kenshir0f/work/gatsby-portfolio/src/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Profile = makeShortcode("Profile");
const About = makeShortcode("About");
const Resume = makeShortcode("Resume");
const Works = makeShortcode("Works");
const Blogs = makeShortcode("Blogs");
const Rewards = makeShortcode("Rewards");
const Skills = makeShortcode("Skills");
const Enjoy = makeShortcode("Enjoy");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Profile mdxType="Profile" />
    <About mt={[40, 64]} mdxType="About" />
    <Resume mt={64} mdxType="Resume" />
    <Works mt={64} mdxType="Works" />
    <Blogs mt={64} mdxType="Blogs" />
    <Rewards mt={64} mdxType="Rewards" />
    <Skills mt={64} mdxType="Skills" />
    <Enjoy mt={[60, 120]} mdxType="Enjoy" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      